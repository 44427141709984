import { useRoutes } from "raviger";
import React, { ReactNode } from "react";
import AppContainer from "../components/AppContainer";
import { LocalEnv } from "../types/EnvTypes";
import RouterFallback from "./RouterFallback";

const Home = React.lazy(()=>import("../components/home/Home"));
const Blog = React.lazy(()=>import("../components/blog/Blog"));
const BlogPost = React.lazy(()=>import("../components/blog/BlogPost"));

const fallback = <RouterFallback/>

function LazyLoad(props : {children : ReactNode}){
    return (
        <React.Suspense fallback={fallback}>
            {props.children}
        </React.Suspense>
    )
}

export default function AppRouter(props : {localEnv : LocalEnv}) {
    const homeRoute = (
        <LazyLoad>
            <Home localEnv={props.localEnv}/>
        </LazyLoad>
    );
    const routes = {
        '/' : () => homeRoute,
        '/home' : () => homeRoute,

        '/blog' : () => <LazyLoad>
            <Blog localEnv={props.localEnv}/>
        </LazyLoad>,

        '/blog/:id' : ({id} : {id : string}) => <LazyLoad>
            <BlogPost blogID={Number(id)}/>
        </LazyLoad>,
    
        //"/board/:id" : ({id} : {id : string}) => <React.Suspense fallback={fallback}><BoardElement boardID={Number(id)} localEnv={props.localEnv} /></React.Suspense>,
    }    
    const routeResult = useRoutes(routes);
    return <AppContainer localEnv = {props.localEnv}>{routeResult}</AppContainer>;
}