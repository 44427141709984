import { ReactNode } from "react"

type SocialContent = {
    title: string,
    icon: `fa${"r" | "s" | "l" | "b" | "t" | "d"} fa-${string}`,
    url: string
}

type ContactType = {
    address: ReactNode,
    mobile: string,
    email: string,
    socials: SocialContent[]
}
const SocialContents: SocialContent[] = [
    {
        title: "Whatsapp",
        icon: "fab fa-whatsapp",
        url: "https://wa.me/919328774660"
    },
    {
        title: "Facebook",
        icon: "fab fa-facebook",
        url: "https://www.facebook.com/vijay.dandiwala"
    },
    {
        title: "LinkedIn",
        icon: "fab fa-linkedin-in",
        url: "https://www.linkedin.com/in/vijay-dandiwala-06552430"
    },
    {
        title: "Twitter",
        icon: "fab fa-twitter",
        url: "https://twitter.com/VDandiwala?t=P5uKcNHaN_ZVFF0y_CNSbA&s=08"
    },
    {
        title: "Quora",
        icon: "fab fa-quora",
        url: "https://www.quora.com/profile/Vijay-Dandiwala?ch=3&oid=61835654&share=697a01dd&srid=oMmx&target_type=user"
    },
    {
        title: "Location",
        icon: "fas fa-location-dot",
        url: "https://maps.app.goo.gl/Dq42fuCsmJujL9rL6"
    }
]

export const ContactContent: ContactType = {
    address: <>
        Vijay Dandiwala,<br />
        101 Aashray, Aakanksha Nagri,<br />
        Behind Croma Show Room,<br />
        Near Shravan Chokdi, Dahej Road,<br />
        Bharuch 392001 Gujarat
    </>,
    mobile: "93287 74660",
    email: "hr.ir.vijaydandiwala@gmail.com", //vijaydandiwala@gmail.com
    socials: SocialContents
}