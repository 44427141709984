import { ContactContent } from '../../content/Socials';
import '../../css/footer.scss';
import logo from '../../med/logo.png';

export default function Footer() {
    return (
        <footer>
            <div className="v-footer-wrapper">
                <div className="v-footer-logo">
                    <img src={logo} alt="Vijay Dandiwala Logo" />
                </div>
                <div className="v-footer-details">
                    <a href={`mailto:${ContactContent.email}`} className="v-footer-contact">
                        <i className="fad fa-envelope"></i>
                        <div>
                            {ContactContent.email}
                        </div>
                    </a>
                    <br />
                    <a href={`${ContactContent.mobile}`} className="v-footer-contact">
                        <i className="fad fa-phone"></i>
                        <div>
                            {ContactContent.mobile}
                        </div>
                    </a>
                    <br />
                    <div className="v-footer-contact">
                        <a href="https://maps.app.goo.gl/Dq42fuCsmJujL9rL6" target="_blank" rel="noreferrer">
                            <i className="fad fa-location-dot"></i>
                        </a>
                        <div>
                            {ContactContent.address}
                        </div>
                    </div>
                </div>
            </div>
            <div className="v-footer-b">
                &copy; Vijay Dandiwala
                &middot;
                A <a
                    href="https://shivank.dev"
                    className="v-shivank"
                >
                    Shivank Kacker
                </a> website
            </div>

        </footer>
    )
}