import { ReactNode } from "react";
import { LocalEnv } from "../types/EnvTypes";
import Footer from "./footer/Footer";
import Header from "./header/Header";

export default function AppContainer(props : {children : ReactNode, localEnv : LocalEnv}){
    return (
        <>
            <Header/>
            {props.children}
            <Footer/>
        </>
    )
}