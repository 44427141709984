//import { Link } from 'raviger';
import { Link, usePath } from 'raviger';
import { useEffect, useState } from 'react'
import '../../css/header.scss'
import logo from '../../med/logo-2.png'

export default function Header(){

    const basePath = usePath();

    const navButtons : [string, string, boolean][] = [
        ["/home","Home", true],
        ["/home#about-us", "About Us", false],
        ["/home#services", "Services", false],
        ["/home#achievements", "Achievements", false],
        ["/home#gallery", "Gallery", false],
        ["/home#contact", "Contact", false],
        ["/blog", "Blog", true]
    ]

    const [headerState, setHeaderState] = useState(false);
    const [offset, setOffset] = useState(window.pageYOffset);

    const [menuState, setMenuState] = useState(false);

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    });

    useEffect(()=>{
        if(basePath === "/home" || basePath === "/"){
            if(offset > 100){
                setHeaderState(true);
            }else{
                setHeaderState(false);
            }
        }else{
            setHeaderState(true);
        }
        
    },[offset, basePath])

    return (
        <header className={headerState ? "white" : ""}>
            <a href="/" className={"v-header-title "}>
                <div className='v-header-logo'>
                    <img src={logo} alt="Vijay Dandiwala Logo" />
                </div>
                <div>
                    <div className='v-header-title-title'>
                        Vijay Dandiwala
                    </div>
                    <div className='v-header-title-subtitle'>
                        HR-IR Consultant
                    </div>
                </div>
            </a>
            <div className={`v-header-nav-wrap ${menuState ? "open" : ""}`} >
                {
                    navButtons.map((button, i)=>{
                        if(button[2]){
                            return (
                                <Link href={button[0]} key={i} className="v-header-nav" onClick={()=>setMenuState(false)}>
                                    {button[1]}
                                </Link>
                            )
                        }else{
                            return (
                                <a href={button[0]} key={i} className="v-header-nav" onClick={()=>setMenuState(false)}>
                                    {button[1]}
                                </a>
                            )
                        }
                        
                    })
                }
            </div>
            <div className='v-header-menu-btn'>
                <i className="far fa-bars" onClick={()=>setMenuState(!menuState)}></i>
            </div>
        </header>
    )
}